.profileImageRight {
  width: 42px;
  height: 42px;
  border-radius: 0px 6px 0px 0px;
  max-width: 42px !important;
}

.profileImageLeft {
  width: 42px;
  height: 42px;
  border-radius: 6px 0px 0px 0px;
  max-width: 42px !important;
}
