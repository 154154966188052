/*!

=========================================================
* Material Dashboard PRO React - v1.10.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";

.checkin-grid .MuiTabs-flexContainer {
  justify-content: flex-end;
}

.checkin-grid-loading .MuiTabs-flexContainer {
  display: none;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

/* .rbc-toolbar {
  flex-direction: row-reverse !important;
} */

.rbc-toolbar-label {
  text-align: left !important;
}

div[role="tablist"] {
  flex-wrap: wrap !important;
}

.rbc-day-slot .rbc-event {
  border: 3px solid #fff;
  text-align: left;
}

.rbc-toolbar-label {
  font-size: 26px;
  text-align: center !important;
  display: inline-block;
}

.rbc-btn-group button {
  margin: 0 0 0 2px;
  border-radius: 30px;
}

.rbc-toolbar button,
.rbc-toolbar button:hover,
.rbc-toolbar button:focus,
.rbc-toolbar button:active,
.rbc-toolbar button.active,
.rbc-toolbar button:active:focus,
.rbc-toolbar button:active:hover,
.rbc-toolbar button.active:focus,
.rbc-toolbar button.active:hover,
.open > .rbc-toolbar button.dropdown-toggle,
.open > .rbc-toolbar button.dropdown-toggle:focus,
.open > .rbc-toolbar button.dropdown-toggle:hover {
  font-size: small;
}

.rbc-agenda-table tr {
  background-color: #fff !important;
}

.calendar-widget-container .MuiTab-root {
  padding: 15px 10px !important;
}

.rdrMonth {
  width: 100%;
}

.signature {
  font-family: "Herr Von Muellerhoff", cursive;
  font-size: 4rem;
  line-height: 1;
}
